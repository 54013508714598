import React from "react"
import { HeadingXs } from '../heading/Heading'
import * as styles from './ContactBlock.module.css'

export function ContactBlock () {
  return (
    <div className={styles.component}>
      <div>
        <HeadingXs h={2}>Telefoon</HeadingXs>
        <p><a href="tel:+31642017721">+31 (0)6 42017721</a></p>
      </div>
      <div>
        <HeadingXs h={2}>E-mail</HeadingXs>
        <p><a href="mailto:info@empowermom.nl">info@empowermom.nl</a></p>
      </div>
      <div>
        <HeadingXs h={2}>KVK-nummer</HeadingXs>
        <p>83263810</p>
      </div>
    </div>
  )
}
