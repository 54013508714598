import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd } from '../components/heading/Heading'
import { ContainerMd } from '../components/container/Container'
import { ContactBlock } from '../components/contact-block/ContactBlock'
import { Wysiwyg } from '../components/wysiwyg/Wysiwyg'
import { Cards } from '../components/cards/Cards'

const ContactPage = () => {
  return (
    <Layout title="Contact" desc="Empowermom is een innovatieve, outreachende en laagdrempelige organisatie die zich onderscheid door betrokken te zijn in de thuis-/privésituatie van de (aanstaande) moeder heeft als missie om (aanstaande) moeders in hun kracht te zetten in - misschien wel de kwetsbaarste fase van - hun leven, de transitie/het avontuur van moederschap.">
      <ContainerMd>
        <Wysiwyg>
          <HeadingMd h={2}>
            Back to you!
            <br /><span>Je kunt contact opnemen via één van de onderstaande mogelijkheden, tot gauw!</span>
          </HeadingMd>
        </Wysiwyg>
      </ContainerMd>

      <ContainerMd>
        <ContactBlock />
      </ContainerMd>

      <Cards />
    </Layout>
  )
}

export default ContactPage
